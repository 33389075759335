import { addMonths, lastDayOfMonth, lastDayOfWeek } from 'date-fns';
import { addDays } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
interface IParam {
    setCallback?: Function,
    events?: Array<Record<string, any>>

}

const SelecionarRegiao: React.FC<IParam> = () => {




    return <div className='gap max-1200 row gap  selectRegiao' style={{ justifyContent: 'center', width: '100%' }}>
        <Link tabIndex={0} to={`/conteudos`} onClick={() => window.scrollTo(0, 0)} className='full-width scaleOnHover' style={{ maxWidth: '20%' }}  ><img className='full-width' src="/apoio/ccgl-pelo-brasil2.png" /></Link>

        <Link tabIndex={0} to={`/conteudos/categoria/norte`} onClick={() => window.scrollTo(0, 0)} className='full-width pointer scaleOnHover' style={{ maxWidth: '18%' }}  ><img className='full-width' src="/apoio/Norte.png" /></Link>
        {/*   <Link to={`/conteudos/categoria/nordeste`} onClick={() => window.scrollTo(0, 0)} className='full-width' style={{ maxWidth: '20%' }}  ><img className='full-width' src="/apoio/Nordeste.png" /></Link>
        <Link to={`/conteudos/categoria/centro-oeste`} onClick={() => window.scrollTo(0, 0)} className='full-width' style={{ maxWidth: '18%' }} ><img className='full-width' src="/apoio/Centro-oeste.png" /></Link>
        <Link to={`/conteudos/categoria/sudeste`} onClick={() => window.scrollTo(0, 0)} className='full-width' style={{ maxWidth: '20%' }}  ><img className='full-width' src="/apoio/Sudeste.png" /></Link>
<Link to={`/conteudos/categoria/sul`} onClick={() => window.scrollTo(0, 0)} className='full-width' style={{ maxWidth: '13%' }}  ><img className='full-width' src="/apoio/Sul.png" /></Link> */}
        <Link tabIndex={0} to={`/conteudos/categoria/nordeste`} onClick={() => window.scrollTo(0, 0)} className='full-width scaleOnHover' style={{ maxWidth: '20%' }}  ><img className='full-width' src="/apoio/Nordeste.png" /></Link>

        <Link tabIndex={0} to={`/conteudos/categoria/centro-oeste`} onClick={() => window.scrollTo(0, 0)} className='full-width scaleOnHover' style={{ maxWidth: '20%' }}  >  <img style={{ width: '80%' }} src="/apoio/Centro-oeste.png" />
        </Link>
        <Link tabIndex={0} to={`/conteudos/categoria/sudeste`} onClick={() => window.scrollTo(0, 0)} className='full-width scaleOnHover' style={{ maxWidth: '20%' }}  >  <nav className='full-width'   ><img style={{ width: '85%' }} src="/apoio/Sudeste.png" />


        </nav></Link>
        <Link tabIndex={0} to={`/conteudos/categoria/sul`} onClick={() => window.scrollTo(0, 0)} className='full-width scaleOnHover' style={{ maxWidth: '20%' }}  >  <nav className='full-width'   ><img style={{ width: '70%' }} src="/apoio/Sul.png" />



        </nav></Link>
    </div >


}

export default SelecionarRegiao;